@tailwind base;
@tailwind components;
@tailwind utilities;


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .custom-input{
        @apply w-full px-3 py-2.5 text-sm md:text-base font-normal border-2 border-gray-200 rounded-md outline-none cursor-pointer



    }
    .search-input{
        @apply w-full h-full px-1 py-2 text-sm md:text-base font-medium border border-gray-200  rounded-none outline-none cursor-pointer



    }
    .bubble {
        @apply inline-block w-[152px] h-[152px]  shadow-lg absolute rounded-full;
        @apply transition-opacity duration-1000 ease-in-out bg-small-sprite;
        
        background-size: 1076px 1076px;
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.1);
    }
      /* .bubble {
  
        @apply bg-white  bg-[1076px_1076px];
        @apply rounded-full;
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.1);
        @apply h-[152px] w-[152px];
    
     
    }  */
      
  
   .bubbles {
        @apply h-[600px] overflow-hidden relative;
      } 
      
     .bubbles-wrapper {
        @apply flex flex-col justify-center h-full bg-background ;
      }

    
    

}

 



